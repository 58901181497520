import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import maintenance_os from "../../../Assets/maintenance_os.png";
import Servicing_os from "../../../Assets/servicing_os.png"
import { Helmet } from "react-helmet-async";
import "./maintenance.css";

const Maintenance: React.FC = () => {
  return (
    <Container className="mb-5" fluid>
      <Container>
        <Helmet>
          <title>Tyre Maintenance</title>
        </Helmet>
        <h3 className="maintenance-heading mt-5 mb-5">
          Maintenance and Repair Services / Bulk Tyre Services
        </h3>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col md={4}>
            <Image src={Servicing_os} fluid />
          </Col>

          <Col
            md={8}
            className="align-self-center"
            style={{
              backgroundColor: "#FBF6EE",
            }}
          >
            <p></p>{" "}
            <p
              style={{
                color: "green",
                fontWeight: "bolder",
                backgroundColor: "#EAECCC",
                padding: "0.8rem",
                marginTop: "1rem",
              }}
            >
              <span style={{ color: "green", fontWeight: "bolder" }}>
                At Fort Tyres,
              </span>
              Our services encompass tyre fitting, wheel balancing, alignment,
              and more. We prioritize convenience by providing options at our
              designated service centers or at the customer's location.
            </p>
            <p
              style={{
                color: "green",
                fontWeight: "bolder",
                backgroundColor: "#EAECCC",
                padding: "0.8rem",
                marginTop: "1rem",
              }}
            >
              We also serve corporate entities, including companies, schools,
              hospitals, and more, by offering services like tyre gauging in
              large quantities on their premises.
            </p>
          </Col>
        </Row>
      </Container>
      {/* 
      <Container className="mb-5">
        <Row className="justify-content-center">
          <Col md={6}>
            <h5
              className="maintenance-second-head mt-5 mb-5"
              style={{
                backgroundColor: "#023020",
                padding: "2rem 1.5rem",
                color: "#fff",
                borderRadius: "1.5rem",
                lineHeight: "1.8",
              }}
            >
              "At Fort Tyres, we take pride in offering a range of expert tyre
              Maintenance services designed to cater to all your needs"
            </h5>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-cotent-center gap-4">
          <Col className="maintenance-col" xs={12} sm={6} lg={3}>
            <h6 className="maintenance-col-headers">Balancing</h6>
            <p className="mt-3">
              Tyre balancing is the process of distributing weight evenly around
              the tyre and wheel assembly. Unbalanced tyres can lead to uneven
              wear and a bumpy ride. It's important to balance your tyres.
            </p>
          </Col>

          <Col className="maintenance-col" xs={12} sm={6} lg={3}>
            <h6 className="maintenance-col-headers">Tire Inspection</h6>
            <p className="mt-3">
              Regularly inspect your tyres for damage, such as cuts, bulges, or
              punctures. Any of these issues can compromise tyre safety. If you
              find any damage, consult with out top tyre professional to
              determine whether the tyre needs repair or replacement.
            </p>
          </Col>

          <Col className="maintenance-col" xs={12} sm={6} lg={3}>
            <h6 className="maintenance-col-headers">Tyre Rotation</h6>
            <p className="mt-3">
              Rotating your tyres at regular intervals ensures even wear and
              prolongs their lifespan. At Fort Tyres, we follow your vehicle
              manufacturer's recommendations for the appropriate rotation
              pattern, typically every 6,000 to 8,000 miles.
            </p>
          </Col>
        </Row>
      </Container> */}
    </Container>
  );
};

export default Maintenance;
