import { NavLink } from "react-router-dom";
import { Product } from "../../Types/Product";
import { Card, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Store } from "../../Store";
import { OrderItems } from "../../Types/CartItem";
import { convertProductToCartItem } from "../../Utils/ApiError";
import default_img from "../../Assets/default_img.png"
import "./productitem.css";

const ProductItem = ({ product }: { product: Product }) => {
  const { state, dispatch } = useContext(Store);
  const {
    cart: { orderItems },
  } = state;

  const addToCartHandler = (item: OrderItems) => {
    const existItem = orderItems.find((x) => x._id === product._id);
    const quantity = existItem ? existItem.quantity + 1 : 1;

    // console.log("orderItems", orderItems);
    // console.log("existItem", existItem);
    // console.log("STATE", state);

    if (product.countInStock < quantity) {
      alert("Sorry. Product is out of stock");
      return;
    }
    dispatch({
      type: "CART_ADD_ITEM",
      payload: { ...item, quantity },
    });
    toast.success("Product added to the cart");
  };

  return (
    <Card className="mt-3 product-card">
      <NavLink to={`products/${product._id}`}>
        {/* <img src={product.image.url} alt="tyre" className="image-fluid" /> */}
        <Card.Img
          src={product.image.url}
          // src={default_img}
          alt={product.brand}
          className="img-width"
        />
      </NavLink>
      <Card.Body>
        <NavLink style={{ textDecoration: "none", color: "black" }} to="">
          <Card.Title style={{ color: "green", fontWeight: "bold" }}>
            {product.brand}
          </Card.Title>
          <div
            className="mb-2"
            style={{ borderBottom: "2px solid #004b23 " }}
          ></div>
          <Card.Text style={{ color: "green" }} className="mb-2">
            <span>&#8358;</span>
            {product.price}
          </Card.Text>
          <Card.Text style={{ color: "green" }} className="mb-2">
            {product.size}
          </Card.Text>
        </NavLink>
        {product.countInStock === 0 ? (
          <Button className="product-item-button" variant="danger">
            Out of stock
          </Button>
        ) : (
          <Button
            onClick={() => addToCartHandler(convertProductToCartItem(product))}
            id="add-to-cart"
          >
            Add to cart
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProductItem;
