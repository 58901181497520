import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-toastify";
import { useContext } from "react";
import { Store } from "../../../Store";
import { useNavigate } from "react-router-dom";
import { useCreateOrderMutation } from "../../../Hooks/orderHook";
import { getError } from "../../../Utils/ApiError";
import ApiError from "../../../Types/ApiErrortype";
import { Helmet } from "react-helmet-async";
import { Button, Card, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LoadingBox from "../../LoadingBox/LoadingBox";
import { MdEdit } from "react-icons/md";
import { Image } from "react-bootstrap";
import { TbAlertTriangle } from "react-icons/tb";
import previeworder from "../../../Assets/previeworder.png";
import "./placeorder.css";

const PlaceOrder: React.FC = () => {
  const navigate = useNavigate();

  const { state, dispatch } = useContext(Store);
  const { cart } = state;

  const round2 = (num: number) => Math.round(num * 100 + Number.EPSILON) / 100;

  cart.itemsPrice = round2(
    cart.orderItems.reduce((a, c) => a + c.quantity * c.price, 0)
  );

  cart.deliveryPrice = cart.itemsPrice > 100 ? round2(0) : round2(10);

  cart.totalPrice = cart.itemsPrice + cart.deliveryPrice;

  const { mutateAsync: createOrder, isLoading } = useCreateOrderMutation();

  const placeOrderHandler = async () => {
    try {
      const data = await createOrder({
        orderItems: cart.orderItems,
        deliveryAddress: cart.deliveryAddress,
        itemPrice: cart.itemsPrice,
        deliveryPrice: cart.deliveryPrice,
        totalPrice: cart.totalPrice,
      });
      dispatch({ type: "CART_CLEAR" });
      localStorage.removeItem("orderItems");
      console.log("Data_Response_placeOrder", data);
      navigate(`/order/${data.order._id}`);
    } catch (err) {
      toast.error(getError(err as ApiError));
    }
  };

  return (
    <Container className="place-order-blob-divider" fluid>
      <Helmet>
        <title>Preview Order</title>
      </Helmet>
      <Container>
        <h3 className="my-5 preview-order-text">Preview Order</h3>
        <Row className="justify-content-center">
          <Col md={4}>
            <Image src={previeworder} fluid />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col md={8}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ fontWeight: "bold", color: "green" }}>
                  Shipping
                </Card.Title>
                <Card.Text>
                  <strong>Name:</strong> {cart.deliveryAddress.fullname} <br />
                  <strong>Address: </strong> {cart.deliveryAddress.address},
                  {cart.deliveryAddress.city},{cart.deliveryAddress.country}
                </Card.Text>
                <NavLink className="place-order-edit-btn" to="/shippingpage">
                  <MdEdit /> Edit
                </NavLink>
              </Card.Body>
            </Card>

            <Card className="mb-3">
              <Card.Body>
                <Card.Title style={{ fontWeight: "bold", color: "green" }}>
                  Items
                </Card.Title>
                <ListGroup variant="flush">
                  {cart.orderItems.map((item) => (
                    <ListGroup.Item key={item._id}>
                      <Row className="align-items-center">
                        <Col md={6}>{item.name}</Col>
                        <Col md={3}>
                          <span>{item.quantity}</span>
                        </Col>
                        <Col md={3}>
                          <span>&#8358;</span>
                          {item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <NavLink className="place-order-edit-btn" to="/cart">
                  <MdEdit /> Edit
                </NavLink>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card>
              <Card.Body>
                <Card.Title style={{ fontWeight: "bold", color: "green" }}>
                  Order Summary
                </Card.Title>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <strong>Items</strong>
                      </Col>
                      <Col>
                        <span>&#8358;</span>
                        {cart.itemsPrice.toFixed(2)}
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <strong>Shipping</strong>
                      </Col>
                      <Col>
                        <span>&#8358;</span>
                        {cart.deliveryPrice.toFixed(2)}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>
                        <strong> Order Total</strong>
                      </Col>
                      <Col>
                        <strong>
                          <span>&#8358;</span>
                          {cart.totalPrice.toFixed(2)}
                        </strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="d-grid">
                      <Button
                        type="button"
                        id="place-order-button"
                        onClick={placeOrderHandler}
                        disabled={cart.orderItems.length === 0 || isLoading}
                      >
                        {isLoading ? (
                          <LoadingBox color="white" />
                        ) : (
                          "Place Order"
                        )}
                      </Button>
                      <div style={{ marginTop: "0.5rem" }}></div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <Container
        className="mt-5 mb-3"
        style={
          {
            // fontWeight: "bolder",
            // padding: "1rem",
            // borderRadius: "50px",
          }
        }
      >
        <Row>
          {/* <Col md={8}>
            <p
              className="disclaimer-paragraph"
              style={{
                backgroundColor: "#023020",
                padding: "0.7rem 0.3rem",
                color: "#fff",
                borderRadius: "1.5rem",
                textAlign: "center",
              }}
            >
              Prices may fluctuate. It is advisable to verify them with our
              Customer Representatives before making payments.
            </p>
          </Col> */}

          <div
            style={{
              // backgroundColor: "#023020",
              backgroundColor: "#FBF6EE",
              padding: "0.7rem 0.3rem",
              color: "black",
              // borderRadius: "1.5rem",
              textAlign: "center",
              width: "85%",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "#C1F2B0",
                borderRadius: "25px",
                margin: "0 auto",
                padding: "0.5rem",
                marginBottom: "1rem",
                border: "1px solid transparent",
              }}
            >
              <TbAlertTriangle size="1.5rem" color="white" />
            </div>

            <p
              style={{
                marginTop: "rem",
                color: "green",
                fontWeight: "bolder",
                backgroundColor: "#EAECCC",
                padding: "0.8rem",
                marginBottom: "0",
              }}
            >
              Prices may fluctuate. It is advisable to verify them with our
              Customer Representatives before making payments.
            </p>
          </div>
        </Row>
      </Container>
    </Container>
  );
};

export default PlaceOrder;
