import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Terms: React.FC = () => {
  return (
    <Container>
      <Row>
        <Col>
          <h3>Terms and conditions</h3>
        </Col>
      </Row>
    </Container>
  );
};

export default Terms;
