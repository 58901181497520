import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import HeroSlider from "../../HeroSlider/HeroSlider";
import "./home.css";
import promosales1 from "../../../Assets/promosales1.png";
import promosales2 from "../../../Assets/promosales2.png";
import promosales3 from "../../../Assets/promosales3.png";
// import embersales1 from "../../../Assets/embersales1.jpg";
// import embersales2 from "../../../Assets/embersales2.jpg";
import OtherServices from "../../OtherServices/OtherServices";
import { Helmet } from "react-helmet-async";
import Products from "../../Products/Products";
import { Image } from "react-bootstrap";
import Video from "../../Video/Video";


const Home: React.FC = () => {
  return (
    <main className="mb-5">
      <section>
        <Helmet>
          <title>Fort Tyres</title>
        </Helmet>
        <HeroSlider />
      </section>
      {/* <Container>
        <h3
          className="mt-4"
          style={{
            textAlign: "center",
            color: "#fff",
            fontWeight: "bolder",
            backgroundColor: "#023020",
            padding: "1rem",
            borderRadius: "50px",
          }}
        >
          Special Offer
        </h3>
      </Container> */}

      <section className="marginTop">
        <Video />
      </section>

      <section>
        <Products />
      </section>

      <section>
        {/* <Container className="mt-3">
          <Row className="latest-deal-row">
            <h5 className="latest-deal-heading">Latest Deals</h5>
            <Col className="mt-3 mb-3 hidden" lg={3}>
              <img src={freshArrival} alt="" className="latest-deal" />
            </Col>
            <Col className="mt-3 mb-3" sm={6} md={3}>
              <img src={latest_deal_img} alt="" className="latest-deal-img" />
            </Col>
            <Col className="mt-3 mb-3" sm={6} md={6}>
              <div className="latest-deal-desctiption-container">
                <img src="" alt="" />
                <div>
                  <p style={{ color: "green", fontWeight: "bolder" }}>
                    Brand: Michelin Tyres
                  </p>
                  <h3 style={{ color: "green", fontWeight: "bolder" }}>
                    MICHELIN 215/55R17
                  </h3>
                  <p>
                    At{" "}
                    <span style={{ color: "green", fontWeight: "bolder" }}>
                      Fort Tyres
                    </span>
                    , we're thrilled to unveil our latest automotive gem – the
                    Michelin tire that's redefining the meaning of luxury and
                    performance. Buckle up and get ready to embark on a journey
                    of extravagance like never before!
                  </p>
                  <p style={{ color: "green", fontWeight: "bolder" }}>
                    &#8358;50,000
                  </p>
              
                </div>
              </div>
            </Col>
          </Row>
        </Container>  */}

        <Container>
          <h5 className="mt-5  mb-5 promo-sales-heading">Promo Sales</h5>
          <Row className="justify-content-center gap-3">
            <Col md={3} className="promo-sales-col">
              <div
                style={{
                  backgroundColor: "#C8FFC8",
                  padding: "1rem",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              >
                <h6 className="brand-header">Brand Name: Premio AVZ 1</h6>
                <p className="brand-text">size: 215/60/16</p>
                <p className="brand-text">
                  price: <span>&#8358;</span>
                  24,990
                </p>
              </div>

              <Image src={promosales1} fluid />
            </Col>
            <Col md={3} className="promo-sales-col">
              <div
                style={{
                  backgroundColor: "#C8FFC8",
                  padding: "1rem",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              >
                <h6 className="brand-header">Brand Name: Arivo</h6>
                <p className="brand-text">size: 195/70/15C</p>
                <p className="brand-text">
                  price: <span>&#8358;</span>
                  30,990
                </p>
              </div>

              <Image src={promosales2} fluid />
            </Col>
            <Col md={3} className="promo-sales-col">
              <div
                style={{
                  backgroundColor: "#C8FFC8",
                  padding: "1rem",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              >
                <h6 className="brand-header">Brand Name: Premio AVZ 1</h6>
                <p className="brand-text">size: 225/65/17</p>

                <p className="brand-text">
                  price: <span>&#8358;</span>
                  34,990
                </p>
              </div>

              <Image src={promosales3} fluid />
            </Col>
          </Row>
        </Container>
        {/* <Container className="mt-5">
          <h5
            className="mt-5 mb-5"
            style={{ borderBottom: "2px solid green", paddingBottom: "7px" }}
          >
            <span style={{ color: "red", fontWeight: "bolder" }}>Ember</span>{" "}
            <span style={{ color: "green", fontWeight: "bolder" }}>Sales</span>{" "}
          </h5>
          <Row className="justify-content-center">
            <Col md={4}>
              <Image src={embersales1} fluid />
            </Col>
            <Col md={4}>
              <Image src={embersales2} fluid />
            </Col>
          </Row>
        </Container> */}

        <OtherServices />
      </section>
    </main>
  );
};

export default Home;
