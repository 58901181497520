import Container from "react-bootstrap/Container";
// import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Helmet } from "react-helmet-async";
import "./about.css";
import ourMission from "../../../Assets/ourMission.jpg";
import ourVision from "../../../Assets/ourVision.jpeg";
import aboutUs from "../../../Assets/aboutUs.jpg";
import { Row, Image } from "react-bootstrap";
import Faq from "../../Faq/Faq";

const About: React.FC = () => {
  return (
    <Container className="mb-5" fluid>
      <Helmet>
        <title>About Fort Tyres</title>
      </Helmet>

      <Container
        style={{ backgroundColor: "#C1F2B0", paddingBottom: "1rem" }}
        fluid
      >
        <Container className="mt-5 mb-5">
          <h3 className="aboutus-heading mb-5">About Us</h3>
          <Row className="justify-content-center gap-2">
            <Col md={6} className="align-self-center">
              <Image src={aboutUs} alt="about us" fluid />
            </Col>
            <Col
              md={4}
              className="align-self-center"
              style={{
                backgroundColor: "#FBF6EE",
              }}
            >
              <p
                style={{
                  color: "green",
                  fontWeight: "bolder",
                  backgroundColor: "#EAECCC",
                  padding: "0.8rem",
                  marginTop: "1rem",
                }}
              >
                We are an innovative company, changing the narrative of tyre
                purchase, fittings ad maintenance.
              </p>

              <p
                style={{
                  color: "green",
                  fontWeight: "bolder",
                  backgroundColor: "#EAECCC",
                  padding: "0.8rem",
                }}
              >
                We leverage technology to provide individuals and business
                quality tyre care services at the comfort of their homes and
                offices.
              </p>
              <p
                style={{
                  color: "green",
                  fontWeight: "bolder",
                  backgroundColor: "#EAECCC",
                  padding: "0.8rem",
                }}
              >
                We also provide customized enterprise plans for business ad
                corporate organizations.
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid style={{ paddingBottom: "1rem" }}>
        <Container>
          <Row className="justify-content-center gap-2">
            <h3
              style={{
                color: "green",
                textAlign: "center",
                fontWeight: "bolder",
              }}
              className="mt-5 mb-2"
            >
              Our Mission
            </h3>
            <Col
              md={4}
              className="align-self-center order"
              style={{ backgroundColor: "#FBF6EE" }}
            >
              <p
                style={{
                  color: "green",
                  fontWeight: "bolder",
                  backgroundColor: "#EAECCC",
                  padding: "0.8rem",
                  marginTop: "1rem",
                }}
              >
                We are on a mission to give vehicle owners a stress free
                experience for their tyre care service, including buying, fixing
                and maintenance.
              </p>
            </Col>

            <Col md={6}>
              <Image src={ourMission} alt="mission" fluid />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        style={{
          backgroundColor: "#C1F2B0",
          color: "#fff",
          paddingBottom: "1rem",
        }}
        fluid
      >
        <Container className="mt-2">
          <h3
            style={{
              color: "green",
              textAlign: "center",
              fontWeight: "bolder",
              paddingTop: "3rem",
            }}
            className="mb-5"
          >
            Our Vision
          </h3>
          <Row className="justify-content-center gap-2">
            <Col md={4}>
              <Image src={ourVision} alt="vision" fluid />
            </Col>

            <Col
              md={4}
              className="align-self-center"
              style={{ backgroundColor: "#FBF6EE" }}
            >
              <p
                style={{
                  color: "green",
                  fontWeight: "bolder",
                  backgroundColor: "#EAECCC",
                  padding: "0.8rem",
                  marginTop: "1rem",
                }}
              >
                Our vision is to provide innovative solutions for tyre care
                services.{" "}
              </p>
            </Col>
          </Row>
        </Container>
      </Container>

      <Faq />
    </Container>
  );
};

export default About;
