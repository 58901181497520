import logonew from "../../Assets/logonew.png";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { NavLink, useNavigate } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { FcAbout } from "react-icons/fc";
import { RiAccountCircleFill } from "react-icons/ri";
import { SiGnuprivacyguard } from "react-icons/si";
// import BreadCrumbs from "../BreadCrumbs/BreadCrumb";
import React, { useContext, useState } from "react";
import { useGetFilterSearchQuery } from "../../Hooks/filterSearchHook";
import { useGetLogoutQuery } from "../../Hooks/LogoutHook";
// import FilterSelect from "../FilterSelect/FilterSelect";
import { Store } from "../../Store";
import { Badge } from "react-bootstrap";
// import SearchResults from "../Pages/SearchResults/SearchResults";
// import { Product } from "../../Types/Product";
import "./layout.css";

const Layout: React.FC = () => {
  const {
    state: { cart, userAccessToken },
    dispatch,
  } = useContext(Store);

  // console.log("USER_ACCESS_TOKEN", userAccessToken?.accessToken);

  const [search, setSearch] = useState("");
  const [showDropdown, setshowDropdown] = useState(false);

  const navigate = useNavigate();

  const autoCompleteOptions = [
    { name: "Eternity", id: 1 },
    { name: "Himitto", id: 2 },
    { name: "Infinity", id: 3 },
    { name: "Road Wind", id: 4 },
    { name: "Joyroad", id: 5 },
    { name: "Westlake", id: 6 },
    { name: "Risen", id: 7 },
    { name: "Gladstone", id: 8 },
    { name: "Cocrea", id: 9 },
    { name: "Michelin", id: 10 },
    { name: "BridgeStone", id: 11 },
    { name: "Double King", id: 12 },
    { name: "Transito", id: 13 },
    { name: "Maxxis", id: 14 },
    { name: "Cocrea", id: 15 },
    { name: "Austone", id: 16 },
    { name: "Premio AXZ 1", id: 17 },
    { name: "Arivo", id: 18 },
    { name: "Compasant", id: 19 },
    { name: "Dunlop", id: 20 },
    { name: "Taitong", id: 21 },
    { name: "Truefast", id: 22 },
    { name: "Rodcholder", id: 23 },
    { name: "Boto", id: 24 },
  ];

  const { refetch } = useGetFilterSearchQuery(search);

  const { refetch: recall } = useGetLogoutQuery();

  const onSubmit = async (e: any) => {
    e.preventDefault();
    console.log(e.target.value);
    try {
      await refetch().then((result) =>
        navigate("/searchresults", { state: { products: result.data } })
      );
      // console.log("isLoading", isLoading);
      // console.log("searchProducts", filterSearchProducts);
      setSearch("");
    } catch (err) {}
  };

  // const handleSelect = (filter: string) => {
  //   // setSelect(filter);
  //   console.log("filter", filter);
  // };

  const onSearch = (searchTerm: string) => {
    setSearch(searchTerm);
    setshowDropdown(false);
  };

  const handleInputChange = (e: any) => {
    const newSearch = e.target.value;
    setSearch(newSearch);
    setshowDropdown(newSearch !== "");
  };
  // const checkSearch = () => {
  //   if(search !== "") {
  //     setshowDropdown(true);
  //   }
  // };

  const logoutHandler = () => {
    recall();
    dispatch({ type: "USER_LOGOUT" });
    localStorage.removeItem("userRegistered");
    localStorage.removeItem("userAccessToken");
    localStorage.removeItem("orderItems");
    localStorage.removeItem("deliveryAddress");
  };

  // console.log("filterSearchProducts", filterSearchProducts);

  return (
    <header className="layout-position">
      <Navbar collapseOnSelect expand="lg" className="nav-bgcolor">
        <Container>
          <NavLink to="/">
            <img src={logonew} alt="logo" className="layout-logo image-fluid" />
          </NavLink>

          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            style={{ border: "none" }}
          />

          <Navbar.Collapse
            id="responsive-navbar-nav"
            className="mt-3 nav-container nav-collapse-container"
          >
            <Form
              className="d-flex form-width"
              // onSubmit={onSubmit}
            >
              {/* <FilterSelect onChange={handleSelect} /> */}

              <Form.Control
                value={search}
                onChange={handleInputChange}
                type="search"
                placeholder="Search by brand or size... (Eg 205/65/16 or Michelin)"
                className="me-2 layout-search-input"
                aria-label="Search"
              />

              <Nav.Link
                as={NavLink}
                to="../searchresults"
                href="../searchresults"
              >
                <Button
                  variant="outline-success"
                  id="layout-search-btn"
                  onClick={onSubmit}
                >
                  Search
                </Button>
              </Nav.Link>
            </Form>
            {/* <Button variant={mode}>
              <i>{mode === "light" ? <BsFillSunFill /> : <BsFillMoonFill />}</i>
            </Button> */}

            {showDropdown && (
              <div className="mobile-autocomplete">
                {autoCompleteOptions
                  .filter((item) => {
                    const searchTerm = search.toLocaleLowerCase();
                    const name = item.name.toLocaleLowerCase();

                    return (
                      searchTerm &&
                      name.startsWith(searchTerm) &&
                      name !== searchTerm
                    );
                  })
                  .map((item) => (
                    <div
                      className="dropdown-item mt-2"
                      onClick={() => onSearch(item.name)}
                      key={item.id}
                    >
                      <AiOutlineSearch className="mobile-search-icon" />{" "}
                      {item.name}
                    </div>
                  ))}
              </div>
            )}

            {/* ///////////////////////////// */}
            <Nav.Link
              as={NavLink}
              to="../about"
              className="nav-social-links"
              href="../about"
            >
              {/* <FcAbout className="layout-icon-margin layout-icon" />{" "} */}
              <p className="cart-paragraph">About Us</p>
            </Nav.Link>
            {/* ///////////////////////////// */}

            <Nav.Link
              as={NavLink}
              to="../signup"
              className="nav-social-links"
              href="../signup"
            >
              <SiGnuprivacyguard className="layout-icon-margin layout-icon" />{" "}
              <p className="cart-paragraph">Sign Up</p>
            </Nav.Link>

            {userAccessToken ? (
              <DropdownButton
                id="dropdown-basic-button"
                title={`Hi ${userAccessToken.firstName}`}
              >
                {/* <RiAccountCircleFill className="layout-icon-margin layout-icon" /> */}
                <Dropdown.Item
                  onClick={logoutHandler}
                  href="#/action-1"
                  drop="down"
                  id="signedin-btn"
                >
                  Logout
                </Dropdown.Item>
              </DropdownButton>
            ) : (
              <Nav.Link
                as={NavLink}
                to="../login"
                className="nav-social-links"
                href="../login"
              >
                <RiAccountCircleFill className="layout-icon-margin layout-icon" />{" "}
                <p className="cart-paragraph">Login</p>
              </Nav.Link>
            )}

            <Nav.Link
              as={NavLink}
              to="/cart"
              className="nav-social-links"
              href="/cart"
            >
              <AiOutlineShoppingCart className="layout-icon-margin layout-icon" />{" "}
              <p className="cart-paragraph">Cart</p>
              {cart.orderItems.length > 0 && (
                <Badge className="badge-pill" pill bg="dark">
                  {cart.orderItems.reduce((a, c) => a + c.quantity, 0)}
                </Badge>
              )}
            </Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {showDropdown && (
        <div className="autocomplete mb-2">
          {autoCompleteOptions
            .filter((item) => {
              const searchTerm = search.toLocaleLowerCase();
              const name = item.name.toLocaleLowerCase();

              return (
                searchTerm && name.startsWith(searchTerm) && name !== searchTerm
              );
            })
            .map((item) => (
              <div
                className="dropdown-item mt-2"
                onClick={() => onSearch(item.name)}
                key={item.id}
              >
                <AiOutlineSearch className="layout-icon-margin desktop-search-icon" />{" "}
                {item.name}
              </div>
            ))}
        </div>
      )}

      {/* <BreadCrumbs /> */}
    </header>
  );
};

export default Layout;
