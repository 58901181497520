import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Helmet } from "react-helmet-async";
import fittingimg from "../../../Assets/fittingimg.png";
import financing from "../../../Assets/financing.jpeg"
import "./fitting.css";

const Fitting: React.FC = () => {
  return (
    <Container className="mb-5" fluid>
      <Container>
        <Helmet>
          <title>Tyre Fitting</title>
        </Helmet>
        <h3 className="fitting-heading mt-5">Tyre Care Financing</h3>
      </Container>

      <Container>
        <Row className="justify-content-center">
          <Col className="fitting-img" md={4}>
            <Image src={financing} fluid />
          </Col>
          <Col
            md={8}
            style={{
              backgroundColor: "#FBF6EE",
            }}
            className="fitting-col-bg align-self-center"
          >
            <p
              style={{
                color: "green",
                fontWeight: "bolder",
                backgroundColor: "#EAECCC",
                padding: "0.8rem",
          
              }}
            >
              We offer financial relief through pay-later options and
              installment payment plans for tyre purchases and other services,
              particularly catering to salaried individuals and low-income
              earners.
            </p>
          </Col>
        </Row>
      </Container>

      <Container></Container>

      {/* <Container
        className="mb-5"
        // style={{ backgroundColor: "#023020", padding: "0" }}
      >
        <Row className="justify-content-center">
          <Col md={6}>
            <h5
              className="fitting-second-head mt-5"
              style={{
                backgroundColor: "#023020",
                padding: "2rem 1.5rem",
                color: "#fff",
                borderRadius: "1.5rem",
                lineHeight: "1.8",
              }}
            >
              "At Fort Tyres, we take pride in offering a range of expert tyre
              fitting services designed to cater to all your needs"
            </h5>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="justify-content-center gap-4">
          <Col className="fitting-col" xs={12} sm={6} lg={3}>
            <h6 className="fitting-col-headers mb-3">Wheel Alignment</h6>
            <p className="mt-3">
              To prevent uneven tyre wear and enhance steering stability, we
              offer precise wheel alignment services that keep your vehicle on
              the straight and narrow.
            </p>
          </Col>
          <Col className="fitting-col" xs={12} sm={6} lg={3}>
            <h6 className="fitting-col-headers mb-3">Tyre Replacement</h6>
            <p className="mt-3">
              If your tyres are worn out or damaged beyond repair, we offer a
              wide selection of high-quality tyres from reputable brands,
              ensuring a perfect fit for your vehicle.
            </p>
          </Col>
          <Col className="fitting-col" xs={12} sm={6} lg={3}>
            <h6 className="fitting-col-headers mb-3">Customized Solutions</h6>
            <p className="mt-3">
              If your tyres are worn out or damaged beyond repair, we offer a
              wide selection of high-quality tyres from reputable brands,
              ensuring a perfect fit for your vehicle.
            </p>
          </Col>
        </Row>
      </Container> */}
    </Container>
  );
};

export default Fitting;
