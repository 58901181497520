import { useMutation } from "@tanstack/react-query";
import apiClient from "../apiClient";
import {Subscribe} from "../Types/Subscribe";

export const useSubscribeMutation = () => 
useMutation({
    mutationFn: async({
        email,

    }: {email: string}) => (
        await apiClient.post<Subscribe>("/subscribe", {
            email,
        })
    ).data
})
