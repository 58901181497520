import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";
import "./faq.css";

const Faq: React.FC = () => {
  return (
    <Container className="mt-5">
      <h3
        className="mb-5"
        style={{ textAlign: "center", color: "green", fontWeight: "bolder" }}
      >
        Frequently Asked Questions
      </h3>
      <Row className="justify-content-center mt-3">
        <Col md={6}>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                <h6 className="faq-header">Term and Conditions</h6>
              </Accordion.Header>
              <Accordion.Body>
                <p className="faq-paragraph-color">
                  These electronic terms and conditions serves as a binding
                  contract between a user of forttyres.com and the company Fort
                  tyres.
                </p>

                <p className="faq-paragraph-color">
                  Your use of www.forttyres.com is, therefore, gorverned by the
                  terms and conditions as applicable to forttyres.com. By use of
                  the website, you shall be contracting with Fort tyres.
                </p>

                <p className="faq-paragraph-color">
                  Your use of this website and any of its services subjects you
                  the guildines, policies, terms and conditions applicable to
                  the services.
                </p>

                <p className="faq-paragraph-color">
                  Fort tyres reserves the right, as its sole discretion, to
                  change, modify add or remove portions of these terms of
                  services, at any time. A user is advised to check these terms
                  of use periodically for changes.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>
                <h6 className="faq-header">Payment Terms</h6>
              </Accordion.Header>
              <Accordion.Body>
                <p className="faq-paragraph-color">
                  In your usage of the available payment methods provided by
                  forttyres.com.
                </p>

                <p className="faq-paragraph-color">
                  Fort tyres will not be resposible for any loss or damage that
                  are connected or associated with payment issues from
                  inititating the transaction, a decline of the initiated
                  transaction, any lack of authorization for the transaction or
                  any Bank limits, etc.
                </p>
                <p className="faq-paragraph-color">
                  Payments not confirmed within 24 hours will result in an
                  automatic cancellation of the transaction. Goods and services
                  will only be delivered with a confirmation of the transaction
                  in full.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>
                <h6 className="faq-header">Pricing and Typo Error</h6>
              </Accordion.Header>
              <Accordion.Body>
                <p className="faq-paragraph-color">
                  Fort tyres reserves the right to make changes or updates to
                  the price of the products and services arising from changes in
                  cost quotations from partners.
                </p>

                <p className="faq-paragraph-color">
                  Fort tyres also reserves the right to change modify, add or
                  remove an error noticed with pricing for products and services
                  redered on the website.
                </p>
                <p className="faq-paragraph-color">
                  In this regard, quotations issued by Fort Tyre finance team
                  supercede any computations from website.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header>
                <h6 className="faq-header">Delivery and Shipping Terms</h6>
              </Accordion.Header>
              <Accordion.Body>
                <p className="faq-paragraph-color">
                  Prodcuts and services will only be delivered at the location
                  provided on the receipt. Location changes will attract
                  additional costs.
                </p>

                <p className="faq-paragraph-color">
                  When a buyer is not available to receive products and services
                  at the agreed date/time/location etc, additional charges may
                  apply to rearrange the shipping and delivery.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <h6 className="faq-header">Refund & Return Policies</h6>
              </Accordion.Header>
              <Accordion.Body>
                <p className="faq-paragraph-color">
                  Return and refunds are subject to proof beyond doubt that the
                  product ad services rendered do not equate to transactions
                  made
                </p>

                <p className="faq-paragraph-color">
                  In this case, Fort tyres reserves the right, at it's sole
                  discretion, to provide a compensation option, Shipping and
                  handig fee are however, not refundable.{" "}
                </p>
                <p className="faq-paragraph-color">
                  Products can not be returned following a customer's rough
                  handling, inexperienced usage, or other causes arising from
                  the customer.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="5">
              <Accordion.Header>
                <h6 className="faq-header">Cancellation Terms</h6>
              </Accordion.Header>
              <Accordion.Body>
                <p className="faq-paragraph-color">
                  Ordered products and services cancelled after 12 hours a
                  request was made, and have been processed for delivery, will
                  not be refunded.
                </p>
                <p className="faq-paragraph-color">
                  Ordered products and services cancelled after 12 hours a
                  request was made but is yet to be processed for delivery will
                  be refunded with a handling fee that is applicable to the
                  request.
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4">
              <Accordion.Header>
                <h6 className="faq-header">Governed Law</h6>
              </Accordion.Header>
              <Accordion.Body>
                <p className="faq-paragraph-color">
                  The terms and conditions shall be governed by and interpreted
                  according to the laws of the Federal Republic of Nigeria with
                  Lagos, Nigeria as the place of jurisdiction.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default Faq;
