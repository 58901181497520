import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Helmet } from "react-helmet-async";
import tyrecarerecycle from "../../../Assets/tyrecarerecycle.jpeg";
import "./servicing.css";

const Servicing: React.FC = () => {
  return (
    <Container className="mb-5" fluid>
      <Container>
        <Row>
          <Helmet>
            <title>Tyre Servicing</title>
          </Helmet>
          <h3 className="servicing-heading mt-5 mb-5">Tyre Recycling</h3>

          <Col classame="servicing-img" md={4}>
            <div>
              {" "}
              <Image src={tyrecarerecycle} fluid />
            </div>
          </Col>
          <Col
            className="servicing"
            md={8}
            style={{
              backgroundColor: "#FBF6EE",
            }}
          >
            <p
              style={{
                color: "green",
                fontWeight: "bolder",
                backgroundColor: "#EAECCC",
                padding: "0.8rem",
                marginTop: "1rem",
              }}
            >
              As part of our efforts to reduce used tyre waste in Nigeria, we
              collect and repurpose old tyres into useful products.
            </p>
            <p
              style={{
                color: "green",
                fontWeight: "bolder",
                backgroundColor: "#EAECCC",
                padding: "0.8rem",
                marginTop: "1rem",
              }}
            >
              Project Tyre-Ful Living by Fort Tyres is a recycling initiative
              aimed at repurposing used tyres into usable household materials
              and products for donations to government and not-for-profit
              organizations including public schools, orphanage homes, etc.
            </p>
          </Col>
        </Row>
      </Container>
      {/* 
      <Container>
        <Row className="justify-content-center">
          <Col md={6}>
            <h5
              className="servicing-second-head mb-5"
              style={{
                backgroundColor: "#023020",
                padding: "2rem 1.5rem",
                color: "#fff",
                borderRadius: "1.5rem",
                lineHeight: "1.8",
              }}
            >
              {" "}
              "At Fort Tyres, we take pride in offering a range of expert tyre
              servicing designed to cater to all your needs"
            </h5>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="gap-4">
          <Col className="servicing-col" xs={12} sm={6} lg={3}>
            <h6 className="servicing-col-headers">
              Tyre Tread Depth & Inspection
            </h6>
            <p className="mt-3">
              {" "}
              Adequate tread depth ensures proper traction and grip on the road,
              especially in wet and slippery conditions.
            </p>
            <p>
              At Fort Tyres, we carefully inspect the threading of your tyres
              with our state of the art equipment.
            </p>
          </Col>
          <Col className="servicing-col" xs={12} sm={6} lg={3}>
            <h6 className="servicing-col-headers">
              Schedule Regular Wheel Alignments
            </h6>
            <p className="mt-3">
              Proper wheel alignment is crucial for maintaining even tyre wear
              and ensuring your vehicle's stability and handling.
            </p>
            <p>
              At Fort Tyres, we operate an alignment service that adjusts the
              angles of your wheels to manufacturer specifications, enhancing
              safety and comfort.
            </p>
          </Col>
          <Col className="servicing-col" xs={12} sm={6} lg={3}>
            <h6 className="servicing-col-headers">
              Tyre Replacement & Repair{" "}
            </h6>
            <p className="mt-3">
              When your tyres have reached the end of their usable life or are
              damaged beyond repair, it's time to consider tyre replacement.
            </p>
            <p>
              At Fort Tyres, we help you to choose the right type and size of
              tyre for your vehicle to maintain performance and safety.
            </p>
          </Col>
        </Row>
      </Container> */}
    </Container>
  );
};

export default Servicing;
