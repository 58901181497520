import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactPlayer from "react-player";
import specialoffer from "../../Assets/specialoffer.png";
import "./video.css";
import { Image } from "react-bootstrap";

const Video: React.FC = () => {
  return (
    <Container className="mb-2">
      <Row className="justify-content-center">
        <Col xs={12} lg={6} className="align-self-center">
          <ReactPlayer
            url={"https://youtu.be/57RVZqYC1mo?feature=shared"}
            controls={true}
            className="video-player"
          />
        </Col>
        <Col className="specialoffer" lg={4}>
          <Image src={specialoffer} fluid></Image>
        </Col>
      </Row>
    </Container>
  );
};

export default Video;
