import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useState } from "react";
import "./products.css";
import { useGetProductsQuery } from "../../Hooks/productHook";
import LoadingBox from "../LoadingBox/LoadingBox";
import MessageBox from "../MessageBox/MessageBox";
import { Helmet } from "react-helmet-async";
import { getError } from "../../Utils/ApiError";
import ApiError from "../../Types/ApiErrortype";
import { TbAlertTriangle } from "react-icons/tb";
import ProductItem from "../ProductItem/ProductItem";

const Products: React.FC = () => {
  const { data: listOfProducts, isLoading, error } = useGetProductsQuery();

  const [page, setPage] = useState(1);

  // console.log("page --->", page);
  // console.log("listOfProducts", listOfProducts?.products);

  const PER_PAGE = 10;

  const pages = 12;

  const skip = page * PER_PAGE - PER_PAGE;

  const style = {
    backgroundColor: "#C8FFC8",
    padding: "20px",
  };

  return (
    <Container style={style} fluid>
      <Helmet>
        <title>Products</title>
      </Helmet>
      <Container>
        <h3 className="product mt-2">Products</h3>
      </Container>

      <Container
        className="mb-3"
        style={{
          // backgroundColor: "#023020",

          fontWeight: "bolder",
          padding: "1rem",
          borderRadius: "50px",
        }}
      >
        <Row>
          {/* <Col md={8}> */}

          <div
            style={{
              // backgroundColor: "#023020",
              backgroundColor: "#FBF6EE",
              padding: "0.7rem 0.3rem",
              color: "black",
              // borderRadius: "1.5rem",
              textAlign: "center",
              width: "85%",
              margin: "0 auto",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                backgroundColor: "#C1F2B0",
                borderRadius: "25px",
                margin: "0 auto",
                padding: "0.5rem",
                marginBottom: "1rem",
                border: "1px solid transparent",
              }}
            >
              <TbAlertTriangle size="1.5rem" color="white" />
            </div>

            <p
              style={{
                color: "green",
                fontWeight: "bolder",
                backgroundColor: "#EAECCC",
                padding: "0.8rem",
                marginBottom: "0",
              }}
            >
              Prices may fluctuate. It is advisable to verify them with our
              Customer Representatives before making payments.
            </p>
          </div>

          {/* </Col> */}
        </Row>
      </Container>

      {isLoading ? (
        <LoadingBox />
      ) : error ? (
        <MessageBox variant="danger">{getError(error as ApiError)}</MessageBox>
      ) : (
        <Row className="mt-3">
          {listOfProducts?.products
            .slice(skip, skip + PER_PAGE)
            .map((product) => (
              <Col key={product._id} sm={6} md={4} lg={3}>
                <ProductItem product={product} />
              </Col>
            ))}
        </Row>
      )}
      <Row className="justify-content-center mt-5 mb-5">
        <Col md={6}>
          {
            <div style={{ textAlign: "center", marginBottom: "0.5rem" }}>
              <button
                className="button-margin"
                disabled={page <= 1}
                onClick={() => setPage((prev) => prev - 1)}
              >
                prev
              </button>
            </div>
          }
          <p
            style={{
              textAlign: "center",
              color: "green",
              fontWeight: "bolder",
            }}
          >
            Page: {page} of {pages}
          </p>
          {
            <div style={{ textAlign: "center", marginBottom: "1rem" }}>
              <button
                className="button-margin"
                disabled={page >= pages}
                aria-disabled={page >= pages}
                onClick={() => setPage((prev) => prev + 1)}
              >
                next
              </button>
            </div>
          }

          <div style={{ textAlign: "center" }}>
            {Array.from({ length: pages }, (value, index) => index + 1).map(
              (each) => (
                <button className="button-margin" onClick={() => setPage(each)}>
                  {each}
                </button>
              )
            )}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Products;
